import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 코드관리 화면 관련 기능 클래스
 */
class Code {

    /**
     * 생성자
     */
    constructor() {

        this.systemCode = process.env.VUE_APP_SYSTEM_CODE;
        this.boardInfo = 'CODEINFO'
        this.searchVal = '0'
    }

    /**
     * 코드 목록 조회
     * @param {string} systemCode 
     * @returns 
     */
    getCodeInfo(codeInfo) {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Admin.Biz.CodeBiz'
            request.methodId = 'GetCodeInfo'
            request.parameters.systemCode = process.env.VUE_APP_SYSTEM_CODE
            request.parameters.CodeVal = codeInfo.CodeVal
                
            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows

                if (!!rows.length) resolve(rows)
                else resolve(null)
            }).catch(error => reject(error))
        })
    }

    /**
     * 부모 코드 조회 
     * @param {String} boardInfo
     * @param {String} searchVal
     * @returns 
     */
    getSystemCodeInfo() {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Admin.Biz.CodeBiz'
            request.methodId = 'GetCodeInfoByBoardInfo'
            request.parameters.boardInfo = this.boardInfo
            request.parameters.searchVal = this.searchVal

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows

                if (!!rows.length) resolve(rows)
                else resolve(null)

            }).catch(error => reject(error))
        })
    }

    /**
     *  코드 정보 저장
     * @param {Object} codeInfo 
     * @returns 
     */
    saveCodeInfo(codeInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Admin.Biz.CodeBiz'
            request.methodId = 'CreateCodeInfo'

            request.parameters = codeInfo

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error))
        })
    }

    /**
     *  코드 정보 수정
     * @param {Object} codeInfo 
     * @returns 
     */
     updateCodeInfo(codeInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Admin.Biz.CodeBiz'
            request.methodId = 'UpdateCodeInfo'

            request.parameters = codeInfo

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error))
        })
    }

    /**
     *  코드 정보 삭제
     * @param {Object} codeInfo 
     * @returns 
     */
     deleteCodeInfo(codeInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Admin.Biz.CodeBiz'
            request.methodId = 'DeleteCodeInfo'

            request.parameters = codeInfo

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error))
        })
    }
}

export const code = new Code();
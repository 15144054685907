<template>
    <div class="admin-content">
        <h3>코드관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getCodeInfo" >조회</button>
                    <button class="btn-box green" @click="{
                        state.dialog.isOpen=true;
                        state.dialog.isNew=true;
                    }">신규</button>
                </div>
            </div>
            <div>
                <div class="sch-wrap3">
                    <div>
                        <label>상위코드</label>
                        <div class="select-box">
                            <select v-model="state.parameter.CodeVal">
                                <option selected="selected" value="0">최상위코드</option>
                                <option v-for="item in state.selectBox.items" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName"  />
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 총 개수 -->
        <div class="total-box">
            <p>Total <strong>{{state.dataTable.rowCount}}</strong></p>
        </div>

        <DataTable 
            class="p-datatable-sm"
            columnResizeMode="fit"
            scrollHeight="497px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.codeItems"
            :paginator="true"
            :rowHover="true"
            :rows="15"
            :resizableColumns="true" 
            @row-click="eventHandler.dataTable.onRowClick">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="Code" header="코드값" :sortable="true" :style="{'width': '300px'}" headerClass="tc"></Column>
            <Column field="ParentCode" header="상위코드값" :sortable="true" :style="{'width': '300px'}" headerClass="tc"></Column>
            <Column field="CodeName" header="코드명" :sortable="true" :style="{'width': '300px'}" headerClass="tc"></Column>
            <Column field="CodeDetail" header="코드설명" :sortable="true" headerClass="tc"></Column>
        </DataTable>
        
        <Dialog
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1">
                <div>
                    <label>상위코드명 <i class="required">*</i></label>
                    <div class="select-box">
                       <select v-model="state.dataTable.rowDataModel.ParentCode" :disabled="!state.dialog.isNew">
                            <option selected="selected" value="0">최상위코드</option>
                            <option v-for="item in state.selectBox.items" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName"  />
                       </select>
                    </div>
                </div>
                <div>
                    <label>코드값 <i class="required">*</i></label>
                    <div>
                        <!-- <input class="input-text mr10" ref="codeVal" type="text" v-model.trim="state.dataTable.rowDataModel.CodeVal" maxlength="12" :disabled="!state.dialog.isNew" /> -->
                        <input class="input-text mr10" ref="codeVal" type="text" v-model.trim="state.dataTable.rowDataModel.CodeVal" maxlength="12"/>
                    </div>
                </div>
                <div>
                    <label>코드명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="codeName" type="text" v-model.trim="state.dataTable.rowDataModel.CodeName" maxlength="128" />
                    </div>
                </div>
                <div>
                    <label>코드설명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.dataTable.rowDataModel.CodeDetail" maxlength="256" /> 
                    </div>
                </div>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button v-show="state.dialog.isNew" class="btn-box gray" @click="methods.codeValidation" >저장</button>
                        <button v-show="!state.dialog.isNew" class="btn-box gray" @click="methods.codeValidation" >수정</button>
                        <button v-show="!state.dialog.isNew" :visible="!state.dialog.isNew" class="btn-box red" @click="methods.deleteCodeInfo" >삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { ref, reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { code } from '@/modules/system/PCM_SM0620E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    components: {
    },
    setup() {

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const codeVal = ref(); // 코드값
        const codeName = ref(); // 코드명
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 코드 목록
                codeItems: null,

                // 행 데이터 모델
                rowDataModel: new Object(),

                // 행 개수
                rowCount: 0,
            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "코드 관리",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            selectBox: {
                items: new Array(),
            },
            parameter: {
                CodeVal: "0",
            },
        });

        // 메서드
        const methods = {

            // 코드 목록 조회
            getCodeInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                code.getCodeInfo(state.parameter).then(result => {

                    state.dataTable.codeItems = result;
                    state.dataTable.rowCount = result.length;
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 코드 상세 저장
            saveCodeInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                code.saveCodeInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.result.value != -1) {

                        alertManager.alert.success('저장 되었습니다.')

                        // 목록 재조회
                        methods.getCodeInfo();
                    }else{
                        alertManager.alert.error('코드값이 중복입니다.')
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                });
            },

            // 코드 상세 수정
            updateCodeInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                code.updateCodeInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.success) {
                        
                        alertManager.alert.success('수정 되었습니다.')

                        // 목록 재조회
                        methods.getCodeInfo();
                    }
                    
                }).success(() => {

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });

            },

            // 코드 상세 삭제
            deleteCodeInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                code.deleteCodeInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.success) {
                        
                        alertManager.alert.success('삭제 되었습니다.')

                        // 목록 재조회
                        methods.getCodeInfo();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                });
            },

            // 코드 상세 유효성검사 // TODO CodeVal 중복 체크 로직 추가필요 AS-IS 없음
            codeValidation: () => {

                let validator = state.dataTable.rowDataModel

                // 코드 값
                if (!validator.CodeVal) {

                    codeVal.value.focus()
                    alertManager.alert.warning('코드값을 입력해주세요')
                }
                // 코드 설명
                else if (!validator.CodeName) {

                    codeName.value.focus()
                    alertManager.alert.warning('코드명을 입력해주세요')
                }
                else {

                    // 코드 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveCodeInfo() })
                    else alertManager.confirm.edit().then(() => { methods.updateCodeInfo() })
                }
            }
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {
                
                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    // 행 데이터 모델 설정
                    state.dataTable.rowDataModel = Object.assign({}, event.data);

                    // 팝업 오픈
                    state.dialog.isOpen = true;
                },
            },

            // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {
                    
                    // 신규 다이얼로그 모드
                    if(state.dialog.isNew) {

                        // 상위코드 재설정 초기값: 카테고리
                        state.dataTable.rowDataModel.ParentCode = '0'
                    }
                },

                // hide 이벤트 처리
                hide: () => {

                    // 행 데이터모델 초기화
                    state.dataTable.rowDataModel = new Object();
                    state.dialog.isNew = false;
                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 코드리스트 초기조회
            methods.getCodeInfo()

            // 시스템 코드 초기조회
            common.getSystemCodeInfo('CODEINFO', '0').then(result => state.selectBox.items = result)
        });

        return {
            state,
            code,
            common,
            methods,
            eventHandler,
            codeVal,
            codeName
        }
    }
}
</script>